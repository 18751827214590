import { useTranslation } from 'react-i18next'
import { graphql, Link } from 'gatsby'
import { css } from '@emotion/react'
import {
  Facebook,
  LinkedIn,
  Twitter,
  MailShare,
} from '@dfds-ui/icons/social-media'
import { media } from '@dfds-ui/theme'
import { Button, Headline } from '@dfds-ui/react-components'
import { ChevronLeft } from '@dfds-ui/icons/system'
import { Text } from '@dfds-ui/typography'
import { track } from '@dfds-frontend/tracking'

import { formatDate } from '../utils'
import { PageLayout } from '../components/Layout'
import { Markdown } from '../components/Markdown'
import { RelatedContent } from '../components/RelatedContent'
import { ContactBox } from '../components/ContactBox'
import { FlexCard } from '../components/Card'
import { useLocationProvider } from '../components/LocationProvider/LocationProvider'
import { useLocaleContext } from '../components/LocaleContext'
import { FlexBox } from '../components/FlexBox'
import { useLocalizedLinkTrackingData } from '../components/EntryLink/LocalizedLink'

const iconProps = {
  width: '40px',
  height: '25px',
}

const ShareThis = () => {
  const url = useLocationProvider()
  return url.href ? (
    <FlexBox>
      <a
        target="_blank"
        href={`https://www.facebook.com/sharer/sharer.php?u=${url.href}`}
      >
        <Facebook {...iconProps} />
      </a>
      <a
        target="_blank"
        href={`https://www.linkedin.com/shareArticle?mini=true&url=${url.href}&source=DFDS`}
      >
        <LinkedIn {...iconProps} />
      </a>
      <a target="_blank" href={`https://twitter.com/home?status=${url.href}`}>
        <Twitter {...iconProps} />
      </a>
      <a target="_blank" href={`mailto:?body=${url.href}`}>
        <MailShare {...iconProps} />
      </a>
    </FlexBox>
  ) : null
}

const NewsArticle = ({
  data: {
    content: { page },
  },
}) => {
  const {
    sys: { id },
    title,
    subtitle,
    content,
    location,
    pressContact,
    linksCollection,
    tagsCollection,
    publicationDate,
  } = page

  const { t } = useTranslation()
  const { localeLower } = useLocaleContext()
  const { getLocalizedLinkTrackingData } = useLocalizedLinkTrackingData()

  return (
    <PageLayout spaced={true} sidebar={<ContactBox {...pressContact} />}>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
        `}
      >
        <Link
          to={`/${localeLower}/about/media/news`}
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <ChevronLeft width="24px" height="24px" />
          {t('NEWS-RETURNTONEWS')}
        </Link>
        <ShareThis />
      </div>
      <FlexCard
        css={css`
          margin-bottom: 40px;
        `}
      >
        <Text styledAs={'body'}>
          <time dateTime={publicationDate}>{`${location}, ${formatDate(
            publicationDate,
          )}`}</time>
        </Text>
        <Headline as={'h1'}>{title}</Headline>
        <Text styledAs={'subHeadline'}>{subtitle}</Text>
        <Markdown
          source={content}
          css={css`
            img {
              max-width: 100%;
            }
          `}
        />
      </FlexCard>
      {linksCollection.items[0] && (
        <>
          <Headline
            as={'h2'}
            css={css`
              width: 100%;
              margin-left: 20px;
              margin-right: 20px;

              ${media.greaterThan('m')`
                margin-left: 0;
                margin-right: 0;
              `}
            `}
          >
            {t('NEWS-RELATEDLINKS')}
          </Headline>
          <div
            css={css`
              margin-bottom: 40px;
            `}
          >
            {linksCollection.items.map(
              ({ linkText, destination, ctaButtonTracking, sys, ...rest }) => (
                <Link to={`/${localeLower}/${destination.slug}`} key={sys.id}>
                  <Button
                    variation="outlined"
                    onClick={() =>
                      ctaButtonTracking &&
                      track(
                        getLocalizedLinkTrackingData({
                          linkText,
                          destination,
                          ...rest,
                        }),
                      )
                    }
                    css={css`
                      width: calc(100% - 40px);
                      margin: 0 20px 10px;

                      ${media.greaterThan('m')`
                        width: auto;
                        margin: 0 10px 10px 0;
                      `}
                    `}
                  >
                    {linkText}
                  </Button>
                </Link>
              ),
            )}
          </div>
        </>
      )}
      {tagsCollection.items[0] && (
        <div
          css={css`
            margin-bottom: 40px;
          `}
        >
          <Headline
            as={'h2'}
            css={css`
              width: 100%;
              margin-left: 20px;
              margin-right: 20px;

              ${media.greaterThan('m')`
                margin-left: 0;
                margin-right: 0;
              `}
            `}
          >
            {t('NEWS-RELATEDNEWS')}
          </Headline>
          {tagsCollection.items.map(({ tagName, sys }) => (
            <RelatedContent key={sys.id} tag={tagName} caller={id} />
          ))}
        </div>
      )}
    </PageLayout>
  )
}

export const query = graphql`
  query NewsArticle($id: String!, $locale: String!, $preview: Boolean = false) {
    content: contentful {
      page: newsArticle(id: $id, locale: $locale, preview: $preview) {
        ...NewsArticleFragment
      }
    }
  }
  fragment NewsArticleFragment on contentful_NewsArticle {
    sys {
      id
    }
    title
    subtitle
    content
    location
    pressContact {
      title
      formsLink {
        ...ItemLink
      }
      blockWidth
      contactContent {
        name
        image {
          ...Asset
        }
        jobTitle
        phoneNumber
        email
      }
    }
    linksCollection(limit: 10) {
      items {
        sys {
          id
        }
        linkText
        ctaButtonTracking
        ctaButtonName
        ctaButtonStage
        destination {
          ...UrlSlug
        }
      }
    }
    tagsCollection(limit: 1) {
      items {
        sys {
          id
        }
        tagName
      }
    }
    publicationDate
  }
`

export default NewsArticle
